import { ref } from '@vue/composition-api'

import { pushPopup } from '@/utils/pushPopup'
import { popupsArr } from './arrs'

const comics = [
  { url: require('@/assets/lesson1/scene10_1.svg') },
  { url: require('@/assets/lesson1/scene10_2.svg') },
]

export default {
  name: 'step0',
  setup(props, ctx) {
    const { emit } = ctx
    const active = ref(true)
    const popups = ref([])
    const wrapperMode = ref(false)

    const init = () => {
      pushPopup(popupsArr, popups.value)
    }

    init()

    const nextStep = () => {
      emit('click', 1)
    }

    return {
      active,
      popups,
      wrapperMode,
      comics,
      nextStep,
    }
  },
}
