import { ref } from '@vue/composition-api'

import step0 from './components/step0'
import step1 from './components/step1'
import step2 from './components/step2'

import hex from '@/components/@layout/hex'

export default {
  name: 'lesson4',
  components: {
    step0,
    step1,
    step2,
    hex,
  },
  setup() {
    const active = ref(false)
    const step = ref(0)

    const nextStep = (val) => {
      step.value = val
    }

    return {
      active,
      step,
      nextStep,
    }
  },
}
